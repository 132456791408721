;(function($){

	function Slides() {
		var _ =  this;

		$('.slider').slick({
			dots: false,
			arrows: false,
			infinite: false,
			speed: 500,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500
		});

		$('.slider-blog').slick({
	        vertical: false,
	        dots: false,
	        infinite: true,
	        speed: 300,
	        autoplay: true,
	        autoplaySpeed: 5500,
	        slidesToShow: 2,
	        slidesToScroll: 1,
	        arrows: true,
	        responsive: [
	          {
	            breakpoint: 1000,
	            settings: {
	              slidesToShow: 2,
	              slidesToScroll: 1,
	              dots: false
	            }
	          },
	          {
	            breakpoint: 600,
	            settings: {
	              slidesToShow: 2,
	              slidesToScroll: 1,
	              dots: false
	            }
	          },
	          {
	            breakpoint: 480,
	            settings: {
	              slidesToShow: 1,
	              slidesToScroll: 1,
	              dots: false,
	              arrows: false
	            }
	          }
	        ]
	    });

	    $('.slider-galeria').slick({
            dots: false,
            infinite: true,
            speed: 600,
            cssEase: 'ease',
            arrows: true
        });

        $('.slider-galeria-education').slick({
            dots: true,
            infinite: true,
            speed: 600,
            fade: true,
            arrows: false
        });

        $(document).on('click', '.tab-structure-btn', function(){
        	$('.slider-galeria-education').slick({
	            dots: true,
	            infinite: true,
	            speed: 600,
	            fade: true,
	            arrows: false
	        });
        })
		
	}
	
	new Slides();

}(jQuery));




