;(function($){

    function Maps() {

        var _ =  this;

        _.initialize();
        _.form(event);

    }

    Maps.prototype.initialize =  function() {

        var _ = this;

        directionsDisplay = new google.maps.DirectionsRenderer();
        var latlng        = new google.maps.LatLng(-14.612358, -57.473909);

        var options = {
            scrollwheel: false,
            zoom:15,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        map = new google.maps.Map(document.getElementById("map"), options);

        var pink_parks = [
            {
              elementType: "labels.text",            //Cor dos textos do mapa
              stylers: [
                { color: "#444444" }
              ]
            }, {
              elementType: "labels.text.stroke",     //Cor da Borda dos textos do mapa
              stylers: [
                  { visibility:"off" }            //Oculta a Borda
              ]
            }, {
              "elementType": "geometry.fill",     //Cor do mapa
              "stylers": [
                  { "color": "#f2efe9" }
              ]
            }, {
              "elementType": "geometry.stroke",    //Cor das Bordas do mapa
              "stylers": [
                  { "color": "#cdc6b5" }
              ]
            }, {
              "featureType": "road",                 //Cor das Ruas
              "elementType": "geometry.fill",
              "stylers": [
                  { "color": "#c9c9c9" }
              ]
            }
        ];

        map.setOptions({styles: pink_parks});
        directionsDisplay = new google.maps.DirectionsRenderer();
        directionsDisplay.setMap(map);

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(-14.612358, -57.473909),
            title: "Associação Nativo",
            map: map,
            icon:$("#app_url").val()+'/public/img/icon-location.png',
        });
    }

    Maps.prototype.form =  function(event) {

        var directionsService = new google.maps.DirectionsService();

        $(".main-map form").submit(function(event) {

            event.preventDefault();

            directionsDisplay = new google.maps.DirectionsRenderer();
            var latlng        = new google.maps.LatLng(-14.612358, -57.473909);

            var options = {
                scrollwheel: false,
                zoom:15,
                center: latlng,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };

            map = new google.maps.Map(document.getElementById("map"), options);

            var pink_parks = [
                {
                  elementType: "labels.text",            //Cor dos textos do mapa
                  stylers: [
                    { color: "#444444" }
                  ]
                }, {
                  elementType: "labels.text.stroke",     //Cor da Borda dos textos do mapa
                  stylers: [
                      { visibility:"off" }            //Oculta a Borda
                  ]
                }, {
                  "elementType": "geometry.fill",     //Cor do mapa
                  "stylers": [
                      { "color": "#f2efe9" }
                  ]
                }, {
                  "elementType": "geometry.stroke",    //Cor das Bordas do mapa
                  "stylers": [
                      { "color": "#cdc6b5" }
                  ]
                }, {
                  "featureType": "road",                 //Cor das Ruas
                  "elementType": "geometry.fill",
                  "stylers": [
                      { "color": "#c9c9c9" }
                  ]
                }
            ];

            map.setOptions({styles: pink_parks});
            directionsDisplay = new google.maps.DirectionsRenderer();
            directionsDisplay.setMap(map);


            var request = {
                origin: $("#location_user").val(),
                destination: '-14.612358, -57.473909',
                travelMode: google.maps.TravelMode.DRIVING
            };

            directionsService.route(request, function(result, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(result);
                        var leg = result.routes[0].legs[0];

                        var markerInicio = new google.maps.Marker({
                            position: leg.start_location,
                            map: map,
                            //icon: $("#app_url").val()+'/public/img/icon-location-user.png'
                        });

                        var markerTermino = new google.maps.Marker({
                            position: leg.end_location,
                            map: map,
                            icon: $("#app_url").val()+'/public/img/icon-location.png'
                        });

                    $("#error").html("").css('padding', '0px');
                }else {
                    $("#error").html("Digite um endereço válido. ex: local - rua - bairro, cidade, pais").css({
                        'padding':'.5em 1em',
                        'color' : '#D83317'
                    });
                }
            });
        });
    }

    new Maps();

}(jQuery));